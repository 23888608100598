<template>
  <v-row class="confirmation__payment-schedule">
    <v-col
      v-if="offer.type === 'continuousLoan' || offer.type === 'fixedPeriodContinuousLoan'"
      class="confirmation__payment-schedule__withdrawal-amount--wrapper relative pa-0 pt-4"
      cols="12"
    >
      <cw-text-field
        id="withdrawalAmount"
        ref="withdrawalAmount"
        v-model="form.withdrawalAmount"
        :rules="`required|between:${withdrawal.min / 100},${withdrawal.max / 100}`"
        :class="{ 'busy': $wait.waiting('withdrawalAmount') }"
        :hint="withdrawalAmountHint"
        :label="withdrawalAmountLabel"
        class="mb-6"
        lang="en-150"
        type="number"
        currency-input
      />
      <label class="confirmation__payment-schedule__progress-label">
        <translate>Credit limit</translate> {{ offer.loanLimit | currency({ locale }) }}
      </label>
      <v-progress-linear
        :value="withdrawalDiagram.percentage"
        class="mb-2 mt-1"
        color="primary"
        height="15"
      />
      <v-row
        class="mx-0 mt-0 mb-0 pa-0 flex"
        align="center"
        justify="space-between"
      >
        <p class="mb-0 confirmation__payment-schedule__title pb-1">
          <span
            class="primary confirmation__payment-schedule__indicator"
          />
          <translate>
            Can be withdrawn later
          </translate>
        </p>
        <p
          class="
          confirmation__payment-schedule__total--small confirmation__payment-schedule__remaining"
        >
          {{ withdrawalDiagram.remainder | currency({ locale }) }}
        </p>
      </v-row>
      <v-row
        class="ma-0 pa-0"
        align="center"
        justify="space-between"
      >
        <p class="mb-0 confirmation__payment-schedule__title pb-1">
          <span
            class="primary confirmation__payment-schedule__title__background
            confirmation__payment-schedule__indicator"
          />
          <translate>
            First withdrawal
          </translate>
        </p>
        <p
          class="
            confirmation__payment-schedule__total--small
            confirmation__payment-schedule__remaining"
        >
          {{ withdrawalDiagram.value | currency({ locale }) }}
        </p>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';

export default {
  name: 'CwConfirmationPaymentSchedule',

  components: {
    'cw-text-field': () => import('@shared/components/inputs/CwTextField'),
  },

  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    withdrawalDiagram: {
      percentage: null,
      remainder: null,
      value: null,
    },
  }),

  computed: {
    ...mapGetters({
      locale: 'application/locale',
    }),

    ...mapState({
      initialData: state => state.application.initialData,
      offer: state => state.application.selectedOffer,
      applicationSummary: state => state.application.applicationSummary,
      withdrawalSummary: state => state.application.withdrawalSummary,
    }),

    withdrawal() {
      return {
        min: 50000,
        max: this.offer.loanLimit,
      };
    },

    /**
     * Gettext translations
     */
    withdrawalAmountHint: vm => vm.$gettext('Enter the withdrawal amount'),
    withdrawalAmountLabel: vm => vm.$gettext('First withdrawal'),
  },

  watch: {
    form: {
      handler(form) {
        this.onFormChange(form);
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      setApplicationSummary: 'application/setApplicationSummary',
      setWithdrawalSummary: 'application/setWithdrawalSummary',
    }),

    onFormChange() {
      const { max, min } = this.withdrawal;

      // prevent withdrawal amount from being larger than the max amount in offer
      if (this.form.withdrawalAmount > parseInt(max, 10)) {
        this.form.withdrawalAmount = parseInt(max, 10);
      }

      if (
        this.form.withdrawalAmount
          && this.form.withdrawalAmount <= parseInt(max, 10)
          && this.form.withdrawalAmount >= parseInt(min, 10)
      ) {
        this.updateWithdrawalSummary();
        this.withdrawalDiagram = {
          percentage: 100 - (
            (this.form.withdrawalAmount / parseInt(this.offer.loanLimit, 10)) * 100
          ),
          remainder: this.offer.loanLimit - this.form.withdrawalAmount,
          value: this.form.withdrawalAmount,
        };
      }
    },

    /**
     * Update withdrawal summary
     *
     * @param {String} amount - Withdrawal amount
     */
    updateWithdrawalSummary() {
      const updatedSummary = cloneDeep(this.withdrawalSummary).map((item) => {
        if (item.id === 'withdrawalAmount') {
          item.value = this.$filters.currency(this.form.withdrawalAmount, { locale: this.locale }); // eslint-disable-line
        }

        return item;
      });

      this.setWithdrawalSummary(updatedSummary);
    },
  },
};
</script>

<style lang="scss">
.confirmation {
  &__payment-schedule {

    &__progress-label {
      font-size: 12px;
      color: rgba(0,0,0,0.54);
    }

    &__title {
      font-weight: bold;

      &__background {
        opacity: 0.3;
      }
    }

    &__indicator {
      border-radius: 50%;
      display: inline-block;
      height: 1rem;
      margin-right: 0.5rem;
      overflow: hidden;
      position: relative;
      top: 2px;
      width: 1rem;

      &--waiting {
        opacity: 0.3;
      }

      &--used {
        background-color: transparent;
        border: 1px solid currentColor;
      }
    }

    &__total {
      font-size: 2.5rem;
      line-height: 1.2;

      &--small {
        font-size: 1rem;
        margin-bottom: 0.5rem;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  .confirmation__payment-schedule__withdrawal-amount--wrapper,
  .confirmation__payment-schedule__payment-schedule--wrapper {
    min-height: 80px;
  }
}
</style>
